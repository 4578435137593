/** Search Form */

import { getDomElems } from '../../../../_shared-components/getDomElems';
import { SEARCH_HISTORY } from './constants';
import { setSearchField } from './listboxUtil';

/* gets search param, if present */
const getSearchParam = (param) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

const showClearButton = (searchField, clearButton) => {
  clearButton.removeAttribute('aria-hidden');
  clearButton.setAttribute('data-visible', 'visible');
  searchField.setAttribute('style', 'margin-right: -44px;');
};

const hideClearButton = (searchField, clearButton) => {
  clearButton.setAttribute('aria-hidden', 'true');
  clearButton.setAttribute('data-visible', 'hidden');
  searchField.setAttribute('style', 'margin-right: 0;');
};

const setClearButtonVisibility = (searchField, clearButton) => {
  if (!searchField.value) {
    hideClearButton(searchField, clearButton);
  } else {
    showClearButton(searchField, clearButton);
  }
};

const enableSearchButton = (searchField, searchButton) => {
  if (searchField.value.trim().length === 0) {
    searchButton.classList.add('search__search-button--disabled');
    searchButton.setAttribute('aria-disabled', 'true');
    searchButton.setAttribute('disabled', 'disabled');
  } else if (searchField.value.trim().length > 0) {
    searchButton.classList.remove('search__search-button--disabled');
    searchButton.setAttribute('aria-disabled', 'false');
    searchButton.removeAttribute('disabled');
  }
};

const enableSearchFormSubmit = (searchForm, searchField) => {
  const recentSearchTermSelected = localStorage.getItem(SEARCH_HISTORY.RECENTLY_SELECTED);
  const recentHistoryBox = document.querySelector(SEARCH_HISTORY.SELECTOR);

  if (searchField.value.trim().length === 0
  && (!recentSearchTermSelected || recentSearchTermSelected === SEARCH_HISTORY.DELETE.TEXT)) {
    searchField.setAttribute('required', 'required');
    if (recentSearchTermSelected === SEARCH_HISTORY.DELETE.TEXT) {
      localStorage.removeItem(SEARCH_HISTORY.LOCAL_STORAGE_KEY);
      setTimeout(() => {
        recentHistoryBox?.setAttribute('data-visible', 'hidden');
        recentHistoryBox?.setAttribute('style', 'display: none;');
        searchField.parentElement.setAttribute('aria-expanded', 'false');
      }, 200);
    }
  } else {
    searchField.removeAttribute('required');
    setSearchField(searchField, searchField.value || recentSearchTermSelected);
    searchForm.submit();
  }
};

const updateSearchField = (searchField) => {
  const inputElem = searchField;
  /* clear input */
  inputElem.value = '';
  searchField.focus();
};

/* init */
const init = (selectors) => {
  const searchElems = getDomElems(selectors);
  if (searchElems === null) return;

  const {
    searchForm,
    searchField,
    clearButton,
    searchButton,
  } = searchElems;

  searchField.addEventListener('keyup', setClearButtonVisibility.bind(null, searchField, clearButton));
  searchField.value = getSearchParam('q');
  setClearButtonVisibility(searchField, clearButton);
  enableSearchButton(searchField, searchButton);
  clearButton.addEventListener('click', () => {
    updateSearchField(searchField);
    setClearButtonVisibility(searchField, clearButton);
    enableSearchButton(searchField, searchButton);
  });
  searchField.addEventListener('input', enableSearchButton.bind(null, searchField, searchButton));
  searchField.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') {
      enableSearchFormSubmit(searchForm, searchField);
      localStorage.removeItem(SEARCH_HISTORY.RECENTLY_SELECTED);
    }
  });
};

export default init;
